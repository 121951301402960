import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
// let store = window.localStorage.getItem('store')
// console.log(1111111111, store)
// if (store) store = JSON.parse(store)
const request = axios.create({
  baseURL: '/repair',
  timeout: 60000, //改成60s
})

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers.satoken = store.getters.token
    }
    config.data = config.data || ''
    return config // 返回这个配置对象，如果没有返回，这个请求就不会发送出去
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  (res) => {
    if (res.status != 200) {
      Toast.fail('服务器异常')
      return false
    }

    const response = res.data || {}
    if (response.code != 200) {
      Toast.fail(response.message || '服务器异常')
      return Promise.reject(response)
    }

    console.log(response.data)
    return response.data == 0 ? 0 : response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default request
