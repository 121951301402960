import request from '@/utils/request'

export const medicineSubmit = (data) => {
  return request({
    url: '/medicine/apply/submit',
    method: 'post',
    data,
  })
}

export const medicineCount = (data) => {
  return request({
    url: '/medicine/apply/count',
    method: 'post',
    data,
  })
}

export const medicinePageList = (data) => {
  return request({
    url: '/medicine/apply/pageList',
    method: 'post',
    data,
  })
}
export const approve = (data) => {
  return request({
    url: '/medicine/manage/approve',
    method: 'post',
    data,
  })
}
export const recordList = (data) => {
  return request({
    url: '/medicine/manage/recordList',
    method: 'post',
    data,
  })
}
export const managecount = (data) => {
  return request({
    url: '/medicine/manage/count',
    method: 'post',
    data,
  })
}

export const medicineDetail = (data) => {
  return request({
    url: '/medicine/apply/detail?id=' + data,
    method: 'get',
    data,
  })
}

export const medicineQuery = (data) => {
  return request({
    url: '/medicine/apply/query?name=' + data,
    method: 'get',
    data,
  })
}

export const approveList = (data) => {
  return request({
    url: '/medicine/manage/approveList',
    method: 'post',
    data,
  })
}
