<template>
  <div id="app" class="app-wrap">
    <app-header />
    <keep-alive :include="['customerCarWash', 'managerCarWash', 'mineOrder', 'managerOrder']">
      <router-view class="app-content" />
    </keep-alive>

    <!-- 此处是为了解决过滤器动态引入class，unocss未动态添加的问题 -->
    <div class="color-#20BC7F color-#0778FF color-#FC3F3F color-#FE8F02"></div>
  </div>
</template>
<script>
import appHeader from '@/components/app-header.vue'
export default {
  components: { appHeader },
  mounted() {
    // this.initData()
  },
  methods: {
    async initData() {
      let url = window.location.href.split('#')[0]
      const res = await this.$api.reserve.getScanParam({ url })
      // 初始化数据
      window.wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.appId, // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })

      window.wx.error(function (res) {
        console.log(res)
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })
    },
  },
}
</script>

<style lang="scss">
body {
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
}
#app {
  font-size: 13px;
}
</style>
