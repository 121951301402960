import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/home/'),
  },
  {
    name: 'carWash',
    path: '/customer/car-wash',
    component: () => import('@/views/customer/car-wash/'),
    meta: {
      title: '洗车预约',
      menuType: 'custMenus',
    },
  },
  {
    name: 'carWashReserve',
    path: '/customer/car-wash/reserve',
    component: () => import('@/views/customer/car-wash/reserve'),
    meta: {
      title: '洗车预约',
    },
  },
  {
    name: 'carWashResult',
    path: '/customer/car-wash/result',
    component: () => import('@/views/customer/car-wash/result'),
    meta: {
      title: '洗车预约',
    },
  },
  {
    name: 'mineOrder',
    path: '/mine-order',
    component: () => import('@/views/mine-order/'),
    meta: {
      title: '我的预约',
      menuType: 'custMenus',
    },
  },
  {
    name: 'mineOrderDetail',
    path: '/mine-order/detail',
    component: () => import('@/views/mine-order/detail'),
    meta: {
      title: '预约详情',
    },
  },
  {
    name: 'billiards',
    path: '/customer/billiards',
    component: () => import('@/views/customer/billiards/'),
    meta: {
      title: '台球预约',
      menuType: 'billiardsMenus',
    },
  },
  {
    name: 'billiardsReserve',
    path: '/customer/billiards/reserve',
    component: () => import('@/views/customer/billiards/reserve'),
    meta: {
      title: '台球预约',
    },
  },
  {
    name: 'customerBilliardsResult',
    path: '/customer/billiards/result',
    component: () => import('@/views/customer/billiards/result'),
    meta: {
      title: '台球预约',
    },
  },
  {
    name: 'customerBilliardsMineOrder',
    path: '/customer/billiards/mine-order',
    component: () => import('@/views/customer/billiards/mine-order'),
    meta: {
      title: '我的预约',
      menuType: 'billiardsMenus',
    },
  },
  {
    name: 'billiardsMineOrderDetail',
    path: '/billiards/mine-order/detail',
    component: () => import('@/views/customer/billiards/mine-order/detail'),
    meta: {
      title: '预约详情',
    },
  },
  {
    name: 'managerCarWash',
    path: '/manager/car-wash',
    component: () => import('@/views/manager/car-wash/'),
    meta: {
      title: '洗车预约',
      menuType: 'managerMenus',
    },
  },
  {
    name: 'managerCarOrder',
    path: '/manager/order',
    component: () => import('@/views/manager/order/'),
    meta: {
      title: '洗车订单',
      menuType: 'managerMenus',
    },
  },
  {
    name: 'managerBilliards',
    path: '/manager/billiards',
    component: () => import('@/views/manager/billiards/'),
    meta: {
      title: '台球预约',
      menuType: 'billiardsMangerMenus',
    },
  },
  {
    name: 'managerbilliards',
    path: '/manager/billiards/order',
    component: () => import('@/views/manager/billiards/order'),
    meta: {
      title: '预约订单',
      menuType: 'billiardsMangerMenus',
    },
  },
  {
    name: 'managerBusiness',
    path: '/manager/business',
    component: () => import('@/views/manager/business/'),
    meta: {
      title: '业务管理',
      menuType: 'managerMenus',
    },
  },
  {
    name: 'pay',
    path: '/pay/index',
    component: () => import('@/views/pay/'),
    meta: {
      title: '线上充值',
      menuType: 'payMenus',
    },
  },
  {
    name: 'payWallet',
    path: '/pay/wallet',
    component: () => import('@/views/pay/wallet/'),
    meta: {
      title: '线上充值',
      menuType: 'payMenus',
    },
  },
  {
    name: 'payWalletDetail',
    path: '/pay/wallet/detail',
    component: () => import('@/views/pay/wallet/detail/'),
    meta: {
      title: '线上充值',
      menuType: 'payMenus',
    },
  },
  {
    name: 'payResult',
    path: '/pay/result',
    component: () => import('@/views/pay/result/'),
    meta: {
      title: '线上充值',
      menuType: 'payMenus',
    },
  },
  {
    name: 'parking',
    path: '/customer/parking/index',
    component: () => import('@/views/customer/parking/index/'),
    meta: {
      title: '停车审批',
      menuType: 'parkingMenus',
    },
  },
  {
    name: 'parkingMine',
    path: '/customer/parking/mine',
    component: () => import('@/views/customer/parking/mine/'),
    meta: {
      title: '停车审批',
      menuType: 'parkingMenus',
    },
  },
  {
    name: 'parkingApply',
    path: '/customer/parking/apply',
    component: () => import('@/views/customer/parking/apply/'),
    meta: {
      title: '停车审批',
      menuType: 'parkingMenus',
    },
  },
  {
    name: 'parkingApplyDetail',
    path: '/customer/parking/detail',
    component: () => import('@/views/customer/parking/detail'),
    meta: {
      title: '停车审批',
      menuType: 'parkingMenus',
    },
  },
  {
    name: 'parkingApproval',
    path: '/manager/parking/approval',
    component: () => import('@/views/manager/parking/approval'),
    meta: {
      title: '停车审批',
    },
  },
  {
    name: 'parkingApprovalDetail',
    path: '/manager/parking/detail',
    component: () => import('@/views/manager/parking/detail'),
    meta: {
      title: '停车审批详情',
    },
  },
  {
    name: 'parkingWYApproval',
    path: '/manager/parking-wy/approval',
    component: () => import('@/views/manager/parking-wy/approval'),
    meta: {
      title: '待录入',
      menuType: 'wyMenus',
    },
  },
  {
    name: 'managerParkingWyApprovalTwo',
    path: '/manager/parking-wy/approval2',
    component: () => import('@/views/manager/parking-wy/approval2'),
    meta: {
      title: '已录入',
      menuType: 'wyMenus',
    },
  },
  {
    name: 'parkingWyApprovalDetail',
    path: '/manager/parking-wy/detail',
    component: () => import('@/views/manager/parking-wy/detail'),
    meta: {
      title: '车辆详情',
    },
  },
  {
    name: 'customerEquip',
    path: '/customer/equip',
    component: () => import('@/views/customer/equip'),
    meta: {
      title: '装备展示',
    },
  },
  {
    name: 'customerEquipDetail',
    path: '/customer/equip/dtail',
    component: () => import('@/views/customer/equip/detail'),
    meta: {
      title: '装备详情',
    },
  },
  {
    name: 'medical',
    path: '/health/medical',
    component: () => import('@/views/health/medical/'),
    meta: {
      title: '医疗预约',
      menuType: 'medicalMenus',
    },
  },
  {
    name: 'healthMineOrder',
    path: '/health/mine-order',
    component: () => import('@/views/health/medical/mine-order'),
    meta: {
      title: '我的预约',
      menuType: 'medicalMenus',
    },
  },
  {
    name: 'healthStatistics',
    path: '/health/statistics',
    component: () => import('@/views/health/statistics/'),
    meta: {
      title: '预约记录',
      menuType: 'statisticsMenus',
    },
  },

  {
    name: 'order',
    path: '/health/statistics/order',
    component: () => import('@/views/health/statistics/order'),
    meta: {
      title: '预约记录',
    },
  },
  {
    name: 'healthanalysis',
    path: '/health/analysis',
    component: () => import('@/views/health/statistics/analysis'),
    meta: {
      title: '统计分析',
      menuType: 'statisticsMenus',
    },
  },
  {
    name: 'dianping',
    path: '/customer/dianping',
    component: () => import('@/views/customer/dianping/'),
    meta: {
      title: '大众点评',
      menuType: 'dianpingMenus',
    },
  },

  {
    name: 'dianpingDetail',
    path: '/customer/dianping/detail',
    component: () => import('@/views/customer/dianping/detail/'),
    meta: {
      title: '意见反馈',
    },
  },

  {
    name: 'customerdianpingmineorder',
    path: '/customer/dianping/mine-order',
    component: () => import('@/views/customer/dianping/mine-order'),
    meta: {
      title: '我的点评',
      menuType: 'dianpingMenus',
    },
  },
  {
    name: 'customerdianpingorderdetail',
    path: '/customer/dianping/order-detail',
    component: () => import('@/views/customer/dianping/order-detail'),
    meta: {},
  },
  {
    name: 'customerdianpingorderdetailreply',
    path: '/customer/dianping/order-detail-reply',
    component: () => import('@/views/customer/dianping/order-detail-reply'),
    meta: {},
  },
  {
    name: 'managerdianping',
    path: '/manager/dianping',
    component: () => import('@/views/manager/dianping/'),
    meta: {
      title: '大众点评',
    },
  },

  {
    name: 'managerdianpingdetail',
    path: '/manager/dianping/detail',
    component: () => import('@/views/manager/dianping/detail/'),
    meta: {
      title: '意见反馈',
    },
  },

  {
    name: 'managerdianpingmineorder',
    path: '/manager/dianping/mine-order',
    component: () => import('@/views/manager/dianping/mine-order'),
    meta: {
      title: '我的点评',
      menuType: 'dianpingMenus',
    },
  },
  {
    name: 'managerdianpingorderdetail',
    path: '/manager/dianping/order-detail',
    component: () => import('@/views/manager/dianping/order-detail'),
    meta: {},
  },
  {
    name: 'managerorderDetailReply',
    path: '/manager/dianping/order-detail-reply',
    component: () => import('@/views/manager/dianping/order-detail-reply'),
    meta: {},
  },
  {
    name: 'childActivityIndex',
    path: '/customer/child-activity',
    component: () => import('@/views/customer/child-activity'),
    meta: {
      title: '快乐亲子游活动',
      isWhite: true,
    },
  },
  // 泅渡馆
  {
    name: 'swim',
    path: '/customer/swim',
    component: () => import('@/views/customer/swim/'),
    meta: {
      title: '泅渡馆预约',
      menuType: 'swimMenus',
    },
  },
  {
    name: 'swimReserve',
    path: '/customer/swim/reserve',
    component: () => import('@/views/customer/swim/reserve'),
    meta: {
      title: '泅渡馆预约',
    },
  },
  {
    name: 'customerswimresult',
    path: '/customer/swim/result',
    component: () => import('@/views/customer/swim/result'),
    meta: {
      title: '泅渡馆预约',
    },
  },
  {
    name: 'customerswimmineorder',
    path: '/customer/swim/mine-order',
    component: () => import('@/views/customer/swim/mine-order'),
    meta: {
      title: '我的预约',
      menuType: 'swimMenus',
    },
  },
  {
    name: 'swimmineorderdetail',
    path: '/swim/mine-order/detail',
    component: () => import('@/views/customer/swim/mine-order/detail'),
    meta: {
      title: '预约详情',
    },
  },

  {
    name: 'managerSwim',
    path: '/manager/swim',
    component: () => import('@/views/manager/swim/'),
    meta: {
      title: '泅渡馆预约',
      menuType: 'swimMangerMenus',
    },
  },
  {
    name: 'managerswim',
    path: '/manager/swim/order',
    component: () => import('@/views/manager/swim/order'),
    meta: {
      title: '预约订单',
      menuType: 'swimMangerMenus',
    },
  },
  {
    name: 'customerInclusivePolice',
    path: '/customer/inclusive-police',
    component: () => import('@/views/customer/inclusive-police/index'),
    meta: {
      title: '警保e通',
    },
  },
  {
    name: 'customerInclusivePoliceList',
    path: '/customer/inclusive-police/list',
    component: () => import('@/views/customer/inclusive-police/list'),
    meta: {
      title: '详情',
    },
  },
  {
    name: 'customerInclusivePoliceDetail',
    path: '/customer/inclusive-police/dtail',
    component: () => import('@/views/customer/inclusive-police/detail'),
    meta: {
      title: '详情',
    },
  },
  {
    name: 'customerInclusivePoliceIntroduce',
    path: '/customer/inclusive-police/introduce',
    component: () => import('@/views/customer/inclusive-police/introduce'),
    meta: {
      title: '专栏介绍',
    },
  },

  {
    name: 'customermedicineapply',
    path: '/customer/medicine/apply',
    component: () => import('@/views/customer/medicine/apply'),
    meta: {
      title: '药品申购',
      menuType: 'medicineMenus',
    },
  },
  {
    name: 'customermedicinequery',
    path: '/customer/medicine/query',
    component: () => import('@/views/customer/medicine/query'),
    meta: {
      title: '药品库存查询',
      menuType: 'medicineMenus',
    },
  },
  {
    name: 'customermedicineprogress',
    path: '/customer/medicine/progress',
    component: () => import('@/views/customer/medicine/progress'),
    meta: {
      title: '申购进度',
      menuType: 'medicineMenus',
    },
  },
  {
    name: 'customermedicinedetail',
    path: '/customer/medicine/detail',
    component: () => import('@/views/customer/medicine/detail'),
    meta: {
      title: '申购进度',
    },
  },
  {
    name: 'managermedicineapproval',
    path: '/manager/medicine/approval',
    component: () => import('@/views//manager/medicine/approval'),
    meta: {
      title: '申购审核',
      menuType: 'medicineManagerMenus',
    },
  },
  {
    name: 'managermedicinedetail',
    path: '/manager/medicine/detail',
    component: () => import('@/views//manager/medicine/detail'),
    meta: {
      title: '申购审核',
      menuType: 'medicineManagerMenus',
    },
  },
  {
    name: 'managermedicinerecord',
    path: '/manager/medicine/record',
    component: () => import('@/views//manager/medicine/record'),
    meta: {
      title: '审核记录',
      menuType: 'medicineManagerMenus',
    },
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, form, next) => {
  store
    .dispatch('GET_USER_INFO')
    .then((res) => {
      next()
    })
    .catch((e) => {
      next()
    })
})

export default router
